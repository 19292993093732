<template>
  <div :class="{ licenseTeacher: dialogRegister }">
    <div class="about text-center" style="min-height: 500px;" v-if="!MessageLicence">
      <v-img src="../../assets/admin/wait.png" max-width="68%" min-height="500px" class="mx-auto my-3">
        <div style="position: absolute; bottom: 10px; left: 50%;transform: translate(-50%, -10%);">
          <h1>Please Wait</h1>
          <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </div>

      </v-img>
      <!-- <v-btn @click="EnviarClever">probar</v-btn>
    <v-btn @click="GetDataUser">obtener info user</v-btn>
    <v-btn @click="GetDataSection">obtener info seccion</v-btn>
    <v-btn @click="loginData">Login firebase</v-btn>
    <v-btn @click="pruebalogin">probar verificacion de bd</v-btn> -->
    </div>
    <div v-if="MessageLicence && TypeUser == 'student'" class="license">
      <div style="min-height: 400px; position:relative">
        <p style="position:absolute; top: 17%; left:5%" class="text-h5">Enter the code you created in Lee Lee TE.</p>
        <div class="box-code">
          <v-alert dense outlined type="error" v-if="errorMessage != ''">
            {{ errorMessage }}
          </v-alert>
          <v-row>
            <p class="mx-auto text-h4 pt-3">Class Code:</p>
          </v-row>
          <v-row class="px-5">
            <v-text-field single-line filled outlined v-model="code" @keyup="uppercase"></v-text-field>
          </v-row>
          <div>
            <v-btn x-large depressed :disabled="loadSearchCode" :loading="loadSearchCode" color="primary"
              @click="SearchCode">
              Enter
            </v-btn>
          </div>
        </div>
        <p style="position:absolute; bottom:0; left:5%" class="text-h6">* If you do not have a class code created, you
          need a active license for access.</p>
      </div>
    </div>
    <!-- <v-card v-if="dialogRegister" max-width="850" class="mx-auto mt-10 rounded-corner">
      <div class="text-center" >
        <img class="mt-2" style="width: 125px;" src="../../assets/homepage/logo_leelee_solo.png" alt="Lee Lee">
      </div>
    </v-card> -->
    <div class="card-center" v-if="dialogRegister">
        <div class="manager-textbox">
          <img  style="width: 300px;" src="../../assets/homepage/leelee_te.png" class="manager-portrait" alt="Lee Lee">
          <div class="manager-text">
            <v-row justify="center">
              <v-col class="text-center text-h5 font-weight-bold">WELCOME</v-col>
            </v-row>
            <v-row class="mx-sm-6">
              <v-col cols="12" class="pl-4"> Lee Lee Teacher Edition is a Spanish literacy tool for bilingual educators.</v-col>
              <v-col cols="5" class="pl-4 text-h6">
                Name:
              </v-col>
              <v-col cols="7" class="px-4">
                {{firstName }} {{ lastName }}
              </v-col>
              <v-col cols="5" class="pl-4 pt-0 text-h6">
                Email:
              </v-col>
              <v-col cols="7" class="px-4 pt-0">
                {{ email }}
              </v-col>
              <v-col cols="5" class="pl-4 text-h6" v-if="ShowAskBilungual">
                Bilingual teacher:
              </v-col>
              <v-col cols="7" class="py-0" v-if="ShowAskBilungual">
                <v-select :items="['Yes', 'No']" item-text="name" class="mx-2" return-object  solo
                    v-model="BilingueSelected" ></v-select>
              </v-col>
              <v-col cols="5" class="pl-4 text-h6">
                School:
              </v-col>
              <v-col cols="7" class="py-0">
                <!-- <v-select :items="itemsEscuelas" item-text="name" class="mx-2" return-object label="School" solo
                    v-model="SchoolSelected" @change="VerificarOpciones"></v-select> -->
                <v-autocomplete
                  :items="itemsEscuelas" item-text="name" class="mx-2" return-object label="School" solo
                  v-model="SchoolSelected" @change="VerificarOpciones"
                ></v-autocomplete>
              </v-col>
              <v-col cols="5" class="pl-4 text-h6" v-if="ShowAskGrade">
                Grade:
              </v-col>
              <v-col cols="7" class="py-0" v-if="ShowAskGrade">
                <v-select :items="itemsGrados" label="Grade" class="mx-2" solo v-model="GradeSelected" @change="VerificarOpciones"></v-select>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="6">
                <v-checkbox v-model="CheckboxLicense" @change="VerificarOpciones">
                  <template v-slot:label>
                    <div>
                      I agree to the
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="https://claseleelee.com/license-terms-use/"
                            @click.stop
                            v-on="on"
                          >
                          License and Terms of use
                          </a>
                        </template>
                        Check in web
                      </v-tooltip>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row v-if="isAvailable == 'no'">
              <v-col>
                <v-alert color="red darken-1" dark dense icon="mdi-school" prominent class="text-center text-subtitle-1">
                  <div class="text-h6">
                    License Not Found
      </div>
                  
                  It looks like you don’t have an active license registered with this school. Please reach out to us at info@claseleelee.com for assistance, and we’ll be happy to help you with your request.
              </v-alert>
              </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                  <v-btn :loading="loadingRegister" :disabled="disabledRegister" tile color="success" x-large @click="CreateTeacher">
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Register
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-center">
                  <v-btn color="error" class=" white--text" x-large dark href="https://claseleelee.com/">
                    Cancel
                    <v-icon right dark>
                      mdi-close-thick
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
          </div>
        </div>       
      </div>
      <v-dialog v-model="dialogMessage" max-width="500">
        <v-card>
          <v-card-title class="headline" color="red">License Not Found</v-card-title>
          <v-card-text>
            {{message}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialogMessage = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>  
  </div>
</template>
<script>
import { functions, auth, db } from '../../plugins/firebase'
export default {
  data() {
    return {
      info: null,
      idClever: '',
      access_token: '',
      email: '',
      firstName: '',
      lastName: '',
      MessageLicence: false,//fasle
      code: '',
      errorMessage: '',
      loadSearchCode: false,
      TempTokenStudentReassigned: '',
      isDeleted: false,
      TypeUser: '',//""
      itemsEscuelas: [],
      itemsGrados: [],
      BilingueSelected: "No",
      SchoolSelected: "",
      GradeSelected: "",
      idClever: '',
      isAvailable: "-",
      TokenFirestoreTeacher: "",
      IsBilingual: "",
      loadingRegister: false,
      e6: 1,
      dialogRegister: false,
      disabledRegister: true,
      CheckboxLicense:false,
      UIDDistrict:"",
      ShowAskBilungual: true,
      dialogMessage: false,
      message: '',
      ShowAskGrade: true
    }
  },
  created() {
    // console.log('entro correctamente')
    
  },
  methods: {
    async EnviarClever() {
      console.log("entro a enviar");
      try {
        let codeClever = this.$route.query.code;
        const loginClever = functions.httpsCallable('loginCleverDemo') // loginClever    --- loginCleverDemo
        let res = await loginClever({ code: codeClever, url: 'https://apps.claseleelee.com/oauth2Demo' }) //https://apps.claseleelee.com/oauth2Demo     ---    http://localhost:8080/oauth2Demo
        console.log(res) //  https://claseleelee.com/oauth2
        if (res.data.answer) {
          this.access_token = res.data.tokenRes.access_token;
          this.idClever = res.data.idUserClever.data.id;
          this.GetDataUser()
          // this.GetDataSection()
        } else {
          console.log('error primero')
          this.$router.push({ path: '/errorLog' })
        }
      } catch (error) {
        console.log(error)
        this.$router.push({ path: '/errorLog' })
      }
    },
    async loginData() {
      let localStore = this.$store;
      try {
        auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        // let existe = await this.existsUser(this.email);
        // console.log(existe)
        // if (!existe.result) {
        //   console.log('no existe');
        //   return;
        // }
        const VerifyLogin = functions.httpsCallable('VerifyLoginTeacher')
        await VerifyLogin({ access_token: this.access_token, idClever: this.idClever, email: this.email })
          .then((res) => {
            if (Object.prototype.hasOwnProperty.call(res.data, 'error')) {
              console.log('ocurrio un error')
              this.$router.push({path: '/errorLog'})
              return;
            }
            if (Object.prototype.hasOwnProperty.call(res.data, 'token')) {
              if ((!res.data.crear) && (!res.data.user)) {
                this.MessageLicence = true;
                this.itemsEscuelas = res.data.dataSchool[0];
                this.itemsGrados = res.data.dataSchool[1];
                this.TokenFirestoreTeacher = res.data.token;
                this.dialogRegister = true;
                this.UIDDistrict = res.data.dataSchool[2];
                this.ShowAskBilungual = true;
                this.ShowAskGrade = true;
                if (this.email.includes("@cityscapeschools.")) {
                  this.GradeSelected = "Pre-School";
                  this.BilingueSelected = "Yes";
                  this.ShowAskBilungual = false;
                  this.ShowAskGrade = false;
                }
                if (this.email.includes("@ideapublicschools.")) {
                  this.GradeSelected = "Pre-School";
                  this.BilingueSelected = "Yes";
                  this.ShowAskBilungual = false;
                }
                // this.$router.push({path: '/errorLog'})
                return;
              }
              auth().signInWithCustomToken(res.data.token)
                .then((userCredential) => {
                  //guardamos token para seccion
                  db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: this.access_token })
                  //
                  //localStorage.setItem('token2', res.data.token)
                  if (res.data.crear) {
                    //aqui creamos
                    console.log('creamos')
                    userCredential.user.updateEmail(this.email);
                    const createCustomUser = functions.httpsCallable('createCustomUser')
                    createCustomUser({ uid: this.idClever, email: this.email, firstName: this.firstName, lastName: this.lastName }).then((respues) => {
                      console.log(respues)
                      console.log('termino de crear');
                      this.LoginStoreClever(true);
                    })
                  } else {
                    //logueamos
                    this.LoginStoreClever(false);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
            }
          })
      } catch (error) {
        console.log(error)
        this.$router.push({path: '/errorLog'})
      }
    },
    LoginStoreClever(nuevo) {
      this.$store.dispatch('loginClever', nuevo).then(() => {
        // TODO - verificar los custom claims
        this.$router.push({ path: '/login' })
      }).catch(error => {
        console.log(error);
        this.errorMessage = error;
      });
    },
    pruebalogin() {
      console.log('asd')
      const usersRef = db.collection('SchoolTeacher').doc(this.idClever)

      usersRef.get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            usersRef.onSnapshot((doc) => {
              // en este caso logeamos
              console.log('loguear')
            });
          } else {
            // en este caso creamos 
            console.log('crear')

          }
        });
    },
    GetDataUser() {
      const url = 'https://api.clever.com/v3.0/users/' + this.idClever;
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + this.access_token }
      };

      fetch(url, options)
        .then(res => res.json())
        .then(json => {
          let role = Object.keys(json.data.roles);
          console.log(json)
          console.log(role);
          if (role[0] == "student") {
            this.firstName = json.data.name.first;
            this.lastName = json.data.name.last;
            this.loginDataStudent();
            this.TypeUser = "student";
          } else {
            this.email = json.data.email;
            this.firstName = json.data.name.first;
            this.lastName = json.data.name.last;
            this.loginData();
            this.TypeUser = "teacher";
          }
        })
        .catch(err => console.error('error:' + err));
    },
    GetDataSection() {
      // const url = 'https://api.clever.com/v3.0/users/'+this.idClever+'/mystudents';
      // const options = {
      //   method: 'GET',
      //   headers: {Accept: 'application/json', Authorization: 'Bearer '+this.access_token}
      // };

      // fetch(url, options)
      //   .then(res => res.json())
      //   .then(json => console.log(json))
      //   .catch(err => console.error('error:' + err));
      const url = 'https://api.clever.com/v3.0/users/' + this.idClever + '/sections';
      const options = {
        method: 'GET',
        headers: { Accept: 'application/json', Authorization: 'Bearer ' + this.access_token }
      };

      fetch(url, options)
        .then(res => res.json())
        .then(json => console.log(json))
        .catch(err => console.error('error:' + err));

      // const url2 = 'https://api.clever.com//v3.0/users/60b6e06801144102ed2254ee';
      // const options2 = {method: 'GET', headers: {Accept: 'application/json', Authorization: 'Bearer '+this.access_token}};

      // fetch(url2, options2)
      //   .then(res => res.json())
      //   .then(json => console.log(json))
      //   .catch(err => console.error('error:' + err));
      // const url2 = 'https://api.clever.com/v3.0/users';
      // const options2 = {
      //   method: 'GET',
      //   headers: {Accept: 'application/json', Authorization: 'Bearer '+this.access_token}
      // };

      // fetch(url2, options2)
      //   .then(res => res.json())
      //   .then(json => console.log(json))
      //   .catch(err => console.error('error:' + err));
    },
    SignInCustomTokenTeacher() {
      this.access_token = "ilb37cfc5c7e2fc79a1be525a30c683837c5ec18";
      this.idClever = "5fa8de55935b9b0344c86088";
      this.email = "cargalindo@dallasisd.org";
      this.firstName = "Carlos";
      this.lastName = "Galindo";
      this.loginData();
      this.TypeUser = "teacher";
    },
    SignInCustomTokenStudent() {
      this.access_token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcxMDg2ODIwOSwiZXhwIjoxNzEwODcxODA5LCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay1jYWl3eUBsZWVsZWVhZG1pbjIwMjIuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJzdWIiOiJmaXJlYmFzZS1hZG1pbnNkay1jYWl3eUBsZWVsZWVhZG1pbjIwMjIuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJ1aWQiOiI2NWIwYWJlNmEwNDJlYjA4Y2EzNDRmMzkiLCJjbGFpbXMiOnsic3R1ZGVudCI6dHJ1ZX19.GCgG081qZMsr0WHHFKgRMG0AnguN7gFHe9HFaUxfoRwXE6u8PIdMTRW4w93dPUmdLHOP0b1WB90MyqDJ-6EG6YqVwXs1o85Uo6mLTmL-NF2M3snBHT0acqMTWlOP-KbDsWlQNDgjQALsAfEDVZ3fgj0sYml-PfHK9RVi07Lbjct-9hYp4o00a-AWA7PxZQA-152qEBcFnjqtA2ot2bmRol5xXsZcNBtCWez_tTmvFLEzW1Py-E6jlG_ykUWIuOmBZCBxgwD-tOaWZrYSvHR3Ne1yku36_CsayudvKlg12EEXGl8qgZ8f2ysP5V9k3oDu2qs_MYVYhqV_slU9cpRPNA";
      this.idClever = "65b0abe6a042eb08ca344f39";
      this.email = "";
      this.firstName = "Lino";
      this.lastName = "Lopez Gutierrez";
      this.loginDataStudent();
      this.TypeUser = "student";
    },
    async existsUser(email) {
      let answer = { result: false, create: false }
      let signInMethods = await auth().fetchSignInMethodsForEmail(email);
      if (signInMethods.length == 0) {
        let docVerify = await db.collection("PreUsersClever").doc(email).get();
        if (!docVerify.exists) {
          console.log('No existe!');
          //answer.result = false;
        } else {
          console.log('si existe');
          answer.result = true;
          answer.create = true;
        }
      } else {
        console.log(signInMethods)
        console.log('si existe')
        answer.result = true;
        //answer.create = true;
      }
      return answer;
    },
    async loginDataStudent() {
      try {
        auth().signOut()
        localStorage.removeItem('email');
        localStorage.removeItem('uid');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        const VerifyLogin = functions.httpsCallable('VerifyLoginStudents')
        await VerifyLogin({ access_token: this.access_token, idClever: this.idClever })
          .then((res) => {
            if (!res.data.exist) {
              this.MessageLicence = true;
              return;
            }
            if (res.data.isDeleted) {
              //this.TempTokenStudentReassigned =res.data.token;
              this.isDeleted = true;
              this.MessageLicence = true;
              return;
            }
            if (Object.prototype.hasOwnProperty.call(res.data, 'token')) {
              auth().signInWithCustomToken(res.data.token)
                .then((userCredential) => {
                  db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: res.data.token })
                  if (res.data.crear) {
                    if (res.data.licences < 1) {
                      console.log('no tiene licencia')
                      this.$router.push({ path: '/errorLog' })
                      return;
                    }
                    //aqui creamos
                    console.log('creamos')
                    //userCredential.user.updateEmail(this.email);
                    const createCustomUser = functions.httpsCallable('createCustomStudent')
                    createCustomUser({ uid: this.idClever, firstName: this.firstName, lastName: this.lastName }).then((respues) => {
                      console.log(respues)
                      console.log('termino de crear');
                      this.LoginStoreClever(true);
                    })
                  } else {
                    //logueamos
                    this.LoginStoreClever(false);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
            }
          })
      } catch (error) {

      }
    },
    async SearchCode() {
      this.loadSearchCode = true;   
      //Verificar si existe la licencia
      const CheckLicenses = functions.httpsCallable('CheckLicenses');
      const result=await CheckLicenses({code: this.code});
      
      //if (result.data.answer && result.data.district!='DuomTO0ODAzA5iDxfOyd')//DuomTO0ODAzA5iDxfOyd - DISTRITO Escuela de Lectura
      //if (result.data.answer && result.data.district!='4dhXa83l2rr4k5rimGRO')//Linea añadida para dallasISD 4dhXa83l2rr4k5rimGRO, borrar cuando acabe la prueba
      //if (result.data.answer && (result.data.district!='4dhXa83l2rr4k5rimGRO' && result.data.district!='DuomTO0ODAzA5iDxfOyd'))//Linea a eliminar una vez q pase las pruebas dallas
      // if (result.data.answer){console.log("result.data.licences----->", (result.data.licences<=0));
      //   if (result.data.exactLicences && result.data.licences<=0){//Esto por consultar
      //   //if (result.data.licences<=0){  
      //     this.$router.push({path: '/nolicense'});          
      //     return;
      //   }
      // }else{
      //   this.errorMessage = 'error code'
      //   setTimeout(() => this.errorMessage = '', 4000);
      //   this.loadSearchCode = false;
      //   return;
      // } 
      if (!result.data.answer){
        this.errorMessage = 'error code'
        setTimeout(() => this.errorMessage = '', 4000);
        this.loadSearchCode = false;
        return;
      }    
      
      const CreateStudetnCode = functions.httpsCallable('CreateStudetnCode');
      CreateStudetnCode({ uid: this.idClever, firstName: this.firstName, lastName: this.lastName, code: this.code, isDeleted: this.isDeleted, automaticAdmin: result.data.automaticAdmin, licences: result.data.licences, exactLicences: result.data.exactLicences }).then((respues) => {
        console.log(respues);
        //console.log('termino de crear');
        if (respues.data.answer) {

          auth().signInWithCustomToken(respues.data.token).then((userCredential) => {
            db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: respues.data.token }).then(() => {
              this.LoginStoreClever(true);
            });
            console.log(userCredential)
          })

        } else {
          this.errorMessage = 'error code'
          setTimeout(() => this.errorMessage = '', 4000);
          this.loadSearchCode = false;
          if (Object.prototype.hasOwnProperty.call(respues.data, 'message')){
            this.dialogMessage=true;
            this.message=respues.data.message;
            console.log("ESTE ES UN MENSAJE: ",respues.data.message);
          }
        }
        //this.LoginStoreClever(true);
      });
    },
    uppercase() {
      this.code = this.code.toUpperCase();
    },
    async CreateTeacher() {
      this.loadingRegister = true;
      this.disabledRegister = true
      const grades=["Pre-School","Kindergarten","1st Grade","2nd Grade","3rd Grade","4th Grade","5th Grade"];

      // if (this.BilingueSelected == 'Yes' && (this.GradeSelected == "Pre-School" || this.GradeSelected == "Kindergarten" || this.GradeSelected == "1st Grade" || this.GradeSelected == "2nd Grade")) { //Codigo de antes mod por prueba dallas
      // // if (this.BilingueSelected == 'Yes' && grades.includes(this.GradeSelected)) {
      //   this.isAvailable = "yes";
      // } else {
      //   this.isAvailable = "no";
      //   this.loadingRegister = false;
      //   return
      // }
      if (this.email.includes("@grayusd.") || this.email.includes("@ideapublicschools.") || this.email.includes("@dallasisd.")) {
        
      }else{
        this.isAvailable = "no";
        this.loadingRegister = false;
        return
      }
      // if (this.email.includes("@grayusd.")) {
      //   if (this.GradeSelected =="Kindergarten" || this.GradeSelected =="1st Grade") {
      //     this.isAvailable = "yes";
      //   } else {
      //     this.isAvailable = "no";
      //     this.loadingRegister = false;
      //     return
      //   }
      // }
      if (this.email.includes("@ideapublicschools.")) {
        if (this.GradeSelected =="Kindergarten" || this.GradeSelected =="1st Grade") {
          this.isAvailable = "yes";
        } else {
          this.isAvailable = "no";
          this.loadingRegister = false;
          return
        }
      }
      // if (this.email.includes("@dallasisd.")) {
      //   // let School = "XeW6tmjBcpwuQfA3IM0k";
      //   // if (School != this.SchoolSelected) {
      //   //   this.isAvailable = "no";
      //   //   this.loadingRegister = false;
      //   //   return
      //   // }

      //   if (this.GradeSelected == "Pre-School" || this.GradeSelected =="Kindergarten" || this.GradeSelected =="1st Grade" || this.GradeSelected == "2nd Grade") {
      //     this.isAvailable = "yes";
      //   } else {
      //     this.isAvailable = "no";
      //     this.loadingRegister = false;
      //     return
      //   }
      // }
      let userCredential = await auth().signInWithCustomToken(this.TokenFirestoreTeacher);
      db.collection("TempTokensClever").doc(this.idClever).set({ date: Date.now(), token: this.access_token });
      userCredential.user.updateEmail(this.email);
      const createCustomTeacherRegister = functions.httpsCallable('createCustomTeacherRegister');
      let data={ 
        uid: this.idClever, 
        email: this.email, 
        firstName: this.firstName, 
        lastName: this.lastName, 
        School: this.SchoolSelected, 
        GradeSelected: this.GradeSelected, 
        UIDDistrict: this.UIDDistrict, 
        leeLeeV2: true//grades.includes(this.GradeSelected, 4) 
      };
      console.log(data);
      let respues = await createCustomTeacherRegister(data);
      console.log(respues);
      if (Object.prototype.hasOwnProperty.call(respues.data, 'message')){
        this.dialogMessage=true;
        this.message=respues.data.message;
        console.log("ESTE ES UN MENSAJE: ",respues.data.message);
        this.loadingRegister = false;
        return;
      }
      this.e6 = 3;
      this.LoginStoreClever(true);
      this.loadingRegister = false;
    },
    VerificarOpciones(){
      console.log(this.SchoolSelected);
      if (this.GradeSelected != "" && this.SchoolSelected != "" && this.CheckboxLicense == true) {
        if (this.isAvailable != "no") {
          this.disabledRegister = false;
        }
      } else {
        this.disabledRegister = true;
      }
     
    }
  },
  mounted() {
    this.EnviarClever();
    // this.SignInCustomTokenTeacher();
    // this.SignInCustomTokenStudent();
  }
}
</script>

<style scoped>
.license {
  background-image: url('../../assets/admin/code.png');

  background-position: center;
  background-repeat: no-repeat, repeat-y;
  background-size: cover;
  height: 100vh;
}

.licenseTeacher {
  background-image: url('../../assets/admin/FondoLetras.png');
  background-position: center;
  background-repeat: no-repeat, repeat-y;
  background-size: cover;
  height: 100vh;  
}

.box-code {
  background-color: white;
  border-radius: 25px;
  border: 2px solid #73AD21;
  padding: 10px;
  text-align: center;
  max-width: 650px;
  width: 550px;
  position: absolute;
  bottom: 70px;
  left: 5%;
}
.rounded-corner{
  border-radius: 30px;
}

.manager-textbox {
    background-color: #f4f6f9;
    position: relative;
    margin-top: 70px;
    max-width: 850px;
    border-radius: 50px;
}

.manager-text {
    margin-top: 40px;
    padding-top: 50px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
}
.manager-portrait {
 position: absolute;
 top: -60px;
 left: 50%;
 transform: translateX(-50%);
}
.card-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .manager-textbox {
    border-radius: 0px;
  }
  .manager-text {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .manager-portrait {
    top: -30px;
  }
}
</style>